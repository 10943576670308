<template>
    <div>
        <div>
            <!--            <div v-if="type === 2" class="mt20">-->
            <div class="mt20">
                <Table class="fix-table-body-center" :columns="columns" :data="data">
                    <slot
                        v-if="$userInfo.type === 2"
                        slot="action"
                        slot-scope="{ row }"
                    >
                        <!-- <Button
                            v-if="$userInfo.id !== row.id"
                            type="text"
                            icon="md-trash"
                            :disabled="operationType !== 2"
                            @click="deleteFromOrg(row)"
                        >
                            移除
                        </Button>-->

                        <!--

                            0: {
                                display: '未授权',
                                value: 0,
                                color: '#f2826a',
                            },
                            1: {
                                display: '已授权',
                                value: 1,
                                color: '#f2826a',
                            },
                            2: {
                                display: '已拒绝',
                                value: 2,
                                color: 'blue',
                            }


                            // 区分
                            1，授权-> 未授权
                            0，未授权-> 授权

                        -->
                        <template v-if="row.type!==2">
                            <Button
                                v-if="row.status === 1"
                                type="error"
                                @click="audit(row.id, 0)"
                            >
                                停用
                            </Button>
                            <Button
                                v-else
                                type="info"
                                @click="audit(row.id, 1)"
                            >
                                启用
                            </Button>
                        </template>
                    </slot>
                </Table>
            </div>
        </div>
        <div></div>
    </div>
</template>

<script>
import userInfoMixin from '@/mixins/userInfo.mixin';
import currentOrgMixin from '@/mixins/currentOrg.mixin';

export default {
    mixins: [userInfoMixin, currentOrgMixin],
    //operationType 1 转让管理员；2 从机构中删除用户
    props: {
        operationType: { default: 2 },
    },
    data() {
        return {
            type: _.get(this.$currentOrg, 'member.type') || 1,
            columns: [
                {
                    type: 'index',
                    width: 60,
                    align: 'center',
                },
                {
                    title: '用户名',
                    key: 'nickname',
                },
                {
                    title: '姓名',
                    key: 'username',
                },
                {
                    title: '手机号',
                    key: 'phone',
                }, {
                    title: '角色',
                    key: 'type',  render(h, { row }) {
                        let s = $const.memberType[_.get(row, 'type')];
                        if (!s) {
                            return (
                                <span>--</span>
                            )
                        }

                        return (
                            <span>{s.display}</span>
                        );
                    },
                },
                {
                    title: '状态',
                    key: 'status',
                    render(h, { row }) {

                        let s = $const.memberStatus[_.get(row, 'status')];
                        if (!s) {
                            return (
                                <span>--</span>
                            )
                        }

                        return (
                            <span style={{color: s.color}}>{s.display}</span>
                        );
                    },
                },

                {
                    title: '操作',
                    align: 'center',
                    slot: 'action',
                    width: 180
                },
            ],
            data: [],
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            let res = await $service.memeber.listByCondition();
            this.data = res.data.records;
        },
        async deleteFromOrg(row) {
            this.$Modal.confirm({
                title: '删除人员',
                content: '是否将' + row.username + '从机构中删除？',
                footerhide: true,
                onOk: async () => {
                    await $service.memeber.deleteFromOrg({ id: row.id });
                    await this.get$currentOrg();
                    await this.getData();
                    // console.log("确认删除========" + JSON.stringify(row));
                },
            });
        },
        async audit(id, status) {
            await $service.memeber.audit({ id: id, status: status });
            await this.getData();
        },
    },
};
</script>
<style lang="less" scoped></style>
