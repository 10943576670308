<template>
    <div class="mt20">
        <div class="info-item-wrapper">
            <div class="title bold">
                <Icon type="ios-print-outline" color="#de1e32" />
                默认发票信息
            </div>
            <div class="content pd24">
                <Form
                    ref="formValidate"
                    :model="formValidate"
                    :rules="ruleValidate"
                    :label-width="120"
                >
                    <FormItem label="名称" prop="invoiceName">
                        <Input
                            v-if="isEdit"
                            v-model="formValidate.invoiceName"
                            placeholder="名称"
                            clearable
                        />
                        <span v-else>{{ org.invoiceName }}</span>
                    </FormItem>
                    <FormItem label="纳税人识别号" prop="invoiceSn">
                        <Input
                            v-if="isEdit"
                            v-model="formValidate.invoiceSn"
                            placeholder="纳税人识别号"
                            clearable
                        />
                        <span v-else>{{ org.invoiceSn }}</span>
                    </FormItem>
                    <FormItem label="地址" prop="invoiceAddr">
                        <Input
                            v-if="isEdit"
                            v-model="formValidate.invoiceAddr"
                            placeholder="地址"
                            clearable
                        />
                        <span v-else>{{ org.invoiceAddr }}</span>
                    </FormItem>
                    <FormItem label="电话" prop="invoicePhone">
                        <Input
                            v-if="isEdit"
                            v-model="formValidate.invoicePhone"
                            placeholder="手机机号格式1xxxxxxxxxx，座机号格式010xxxxxxxx"
                            clearable
                        />
                        <span v-else>{{ org.invoicePhone }}</span>
                    </FormItem>
                    <FormItem label="开户行" prop="invoiceBank">
                        <Input
                            v-if="isEdit"
                            v-model="formValidate.invoiceBank"
                            placeholder="开户行"
                            clearable
                        />
                        <span v-else>{{ org.invoiceBank }}</span>
                    </FormItem>
                    <FormItem label="账号" prop="invoiceAccount">
                        <Input
                            v-if="isEdit"
                            v-model="formValidate.invoiceAccount"
                            placeholder="账号"
                            clearable
                        />
                        <span v-else>{{ org.invoiceAccount }}</span>
                    </FormItem>

                    <FormItem>
                        <template v-if="isEdit">
                            <Button
                                class="mr10"
                                type="primary"
                                @click="handleSubmit"
                            >
                                保存
                            </Button>
                            <Button @click="handleCancel"> 取消</Button>
                        </template>
                        <!--  <Button
                            v-if="isView && org.status === 1"
                            type="primary"
                            @click="handleToEdit"
                        > -->
                        <Button
                            v-if="isView && org.adminId === member.id"
                            type="primary"
                            @click="handleToEdit"
                        >
                            编辑
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import currentOrgMixin from '@/mixins/currentOrg.mixin';

export default {
    mixins: [currentOrgMixin],
    data() {
        return {
            editType: 'view',
            org: {},
            member: {},
            formValidate: {
                invoiceName: '',
                invoiceSn: '',
                invoiceAddr: '',
                invoicePhone: '',
                invoiceBank: '',
                invoiceAccount: '',
            },
            ruleValidate: {
                invoiceName: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                invoiceSn: [
                    {
                        required: true,
                        message: '请输入纳税人识别号',
                        trigger: 'blur',
                    },
                ],
                invoiceAddr: [
                    { required: true, message: '请输入地址', trigger: 'blur' },
                ],
                invoicePhone: [
                    { required: true, message: '请输入电话', trigger: 'blur' },
                ],
                invoiceBank: [
                    {
                        required: true,
                        message: '请输入开户行',
                        trigger: 'blur',
                    },
                ],
                invoiceAccount: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                ],
            },
        };
    },
    computed: {
        isView() {
            return this.editType === 'view';
        },
        isEdit() {
            return this.editType === 'edit';
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        handleToEdit() {
            this.editType = 'edit';
        },
        async handleCancel() {
            this.handleReset();
            await this.get$currentOrg();
            await this.loadData();
        },
        async loadData() {
            let org = _.get(this.$currentOrg, 'org');
            let member = _.get(this.$currentOrg, 'member');
            this.org = _.cloneDeep(org);
            this.member = _.cloneDeep(member);
            this.formValidate = _.cloneDeep(org);

            this.editType = 'view';
        },
        async handleSubmit() {
            await new Promise((resolve) =>
                this.$refs.formValidate.validate((valid) => valid && resolve()),
            );

            await $service.org.updateOrg({ ...this.formValidate });

            await this.get$currentOrg();
            await this.loadData();
        },
        handleReset() {
            this.$refs.formValidate.resetFields();
        },
    },
};
</script>
