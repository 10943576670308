<template>
    <Modal
        v-model="show"
        title="充值"
        width="300"
        footer-hide
        closable
        @on-cancel="closeModal"
    >
        <div class="mb10 pointer">
            <Row v-for="(it, idx) of chargeOpts" :key="idx">
                <Col v-for="item of it" :key="item._id" :span="8">
                    <div
                        class="charge-item bg-gray c-black"
                        :class="{ active: item.value === +chargeValue }"
                        @click="changeCharge(item.value)"
                    >
                        {{ item.value }}元
                    </div>
                </Col>
            </Row>
        </div>
        <div>
            <InputNumber
                v-model="chargeValue"
                :step="1"
                class="mb10 w200"
                clearable
                @on-change="changeChargeInput"
            />

            <div class="flex-between flex-v-center mb10">
                <div>支付金额</div>
                <div>
                    <span style="color: #de1e32">¥{{ chargeValue }}</span>
                </div>
            </div>

            <div class="flex-center flex-v-center mb10">
                <div id="qrcode" ref="qrCodeRef" class="wechat_code"></div>
            </div>
            <div class="tc">
                <div>付费即表示同意 《研小助增值服务协议》</div>
                <div>充值可开具发票</div>
            </div>
        </div>
    </Modal>
</template>

<script>
import QRCode from 'qrcodejs2';

const getInitChargeOpts = () => {
    let opts = [10, 30, 50, 100, 300, 500];
    let ret = [];

    while (opts.length) {
        let item = [
            {
                value: opts.shift(),
                _id: $util.getUniqId(),
            },
        ];

        item.push({
            value: opts.shift(),
            _id: $util.getUniqId(),
        });

        item.push({
            value: opts.shift(),
            _id: $util.getUniqId(),
        });
        ret.push(item);
    }

    return ret;
};

let timer = null;
export default {
    name: 'Charge',
    data() {
        return {
            show: false,
            chargeOpts: [],
            chargeValue: void 0,
            currentData: {},
        };
    },
    // watch: {
    //   show() {
    //     clearInterval(timer);
    //   },
    // },
    mounted() {
        this.initQRCode();
    },
    methods: {
        changeChargeInput() {
            if (this.chargeValue) {
                this.getData();
            }
        },
        changeCharge(e) {
            // this.chargeValue = _.get(e, 'target.value') || e || 10;
            if (e) {
                this.chargeValue = e;
                this.getData();
            }
        },
        // 根据字符串生成二维码
        initQRCode() {
            this.qrcode = new QRCode(this.$refs.qrCodeRef, {
                text: 0, //页面地址 ,如果页面需要参数传递请注意哈希模式#
                width: 128, // 二维码宽度 （不支持100%）
                height: 128, // 二维码高度 （不支持100%）
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H,
            });
        },
        // 查看明细
        async getData() {
            let data = await $service.amount.rechargeNative({
                money: this.chargeValue,
            });
            this.currentData = data;
            this.updateCode(data.data);
        },
        updateCode(str) {
            this.qrcode.makeCode(str);
        },
        showModal() {
            clearInterval(timer);
            this.chargeOpts = getInitChargeOpts();
            this.chargeValue = this.chargeOpts[0][0].value;
            this.getData();
            this.show = true;
            timer = setInterval(this.interval, 1e3);
        },
        closeModal() {
            this.show = false;
            clearInterval(timer);
        },
        async interval() {
            let message = this.currentData.message;
            if (!message) {
                return;
            }
            let { data } = await $service.amount.rechargeNativeQuery(message);
            if (data === 'ok') {
                clearInterval(timer);
                this.$Message.success({
                    content: '支付成功',
                    onClose: () => {
                        this.show = false;
                        this.$emit('update');
                    },
                });
            }
        },
    },
};
</script>
<style lang="less" scoped>
@red: #de1e32;
.charge-item {
    border-radius: 4px;
    margin: 5px;
    padding: 5px;

    &.active {
        background: #ffeef1;
        color: @red;
    }
}
</style>
