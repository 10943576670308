<template>
    <div>
        <div>
            <div class="mt20">
                <GeneralInfo :countInfo="countInfo" />

                <!--  感兴趣的品牌  -->
                <div class="mt20">
                    <div class="info-item-wrapper">
                        <div class="title bold">感兴趣的品牌</div>
                        <!-- <div
                            v-if="member.type === 2 && org.status === 1"
                            class="c-red pointer mb10"
                            @click="addBrand"
                        >-->
                        <div
                            v-if="member.type === 2 && org.adminId === member.id"
                            class="c-red pointer mb10"
                            @click="addBrand"
                        >
                            <Icon type="ios-add-circle-outline" />
                            新增品牌
                        </div>
                        <div class="content">
                            <Table
                                :columns="columns"
                                :data="pageBrandList"
                            >
                                <slot
                                    v-if="member.type === 2"
                                    slot="action"
                                    slot-scope="{ row }"
                                >
                                    <div class="flex">
                                        <Button
                                            type="primary"
                                            class="mr10"
                                            @click="setQualification(row)"
                                        >
                                            设置资质
                                        </Button>
                                        <Button
                                            type="primary"
                                            @click="deleteBrand(row)"
                                        >
                                            删除
                                        </Button>
                                    </div>
                                </slot>
                            </Table>
                            <div class="bg-white flex-end pd10">
                                <Page
                                    :current="brandSizer.current"
                                    :total="brandList.length"
                                    @on-change="handleOnChange"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <!--  账户信息  -->
                <div class="mt20">
                    <div class="info-item-wrapper">
                        <div class="title bold">账户信息</div>
                        <div>
                            <Row align="middle" class="mb10">
                                <Col :span="5">
                                    <div class="tr">
                                        <span class="c-gray">可用金额: </span>
                                        <span>{{ amount }}元</span>
                                    </div>
                                </Col>
                                <Col :span="6">
                                    <div class="pdl24" v-if="$currentOrg.isAdmin">
                                        <Button
                                            v-if="org.status === 1"
                                            size="small"
                                            type="primary"
                                            class="mr10"
                                            @click="chargeShow"
                                        >
                                            充值
                                        </Button>
                                        <Button
                                            size="small"
                                            type="primary"
                                            @click="kaifapiao"
                                        >
                                            开发票
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row align="middle" class="mb10">
                                <Col :span="5">
                                    <div class="tr">
                                        <span class="c-gray">冻结金额: </span>
                                        <span>{{ amountFreeze }}元</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row align="middle">
                                <Col :span="5">
                                    <div class="tr">
                                        <span class="c-gray"
                                            >已支付中标服务费:
                                        </span>
                                        <span>{{ amountOut }}元</span>
                                    </div>
                                </Col>
                                <Col :span="6">
                                    <div class="pdl24">
                                        <Button
                                            size="small"
                                            type="primary"
                                            @click="viewDetail"
                                        >
                                            查看明细
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                <FeeDesc />
            </div>
        </div>
        <div>
            <Receipt ref="receipt" :currentOrg="this.$currentOrg" />
        </div>
        <div>
            <ViewDetail ref="viewDetail" />
        </div>
        <div>
            <Charge ref="charge" @update="getData" />
        </div>
        <div>
            <BrandSelect ref="brandSelect" @update="getData" />
        </div>
        <div>
            <Modal
                v-model="qualificationModel"
                title="代理资质"
                @on-ok="qualificationOk"
                @on-cancel="qualificationCancel"
            >
                <Form
                    ref="formInline"
                    :rules="ruleInline"
                    :label-width="120"
                    :model="formInline"
                    inline
                >
                    <FormItem label="经营级别" prop="qualification">
                        <Select
                            v-model="formInline.qualification"
                            class="w200"
                            placeholder="经营级别"
                        >
                            <Option
                                v-for="it of options.qualificationOptions"
                                :key="it.text"
                                :value="'' + it.value"
                            >
                                {{ it.text }}
                            </Option>
                        </Select>
                    </FormItem>
                    <FormItem
                        label="资质到期时间"
                        prop="qualificationExpireTime"
                    >
                        <DatePicker
                            v-model="formInline.qualificationExpireTime"
                            :options="options1"
                            type="date"
                            placeholder="资质到期时间"
                            style="width: 200px"
                        />
                    </FormItem>
                    <FormItem class="wp100" label="资质文件" prop="fileList">
                        <IUpload
                            ref="fileUpload"
                            v-model="formInline.fileList"
                        />
                    </FormItem>
                    <FormItem class="wp100" label="经营区域" prop="qualificationRegion">
                        <Input
                            v-model="formInline.qualificationRegion"
                            placeholder="经营区域"
                            class="w200"
                            clearable
                        />
                    </FormItem>
                </Form>

                <div slot="footer" class="flex-end flex-v-center">
                    <Button @click="qualificationCancel"
                        >取消</Button
                    >
                    <Button type="primary" @click="qualificationOk"
                        >确定</Button
                    >
                </div>
            </Modal>
        </div>
    </div>
</template>

<script>
import IUpload from '../../../../components/IUploadMultiple.vue';
import BrandSelect from './CompanySellerInfo/BrandSelect.vue';
import ViewDetail from './CompanySellerInfo/ViewDetail.vue';
import Charge from './CompanySellerInfo/Charge.vue';
import Receipt from './CompanySellerInfo/Receipt.vue';
import currentOrgMixin from '@/mixins/currentOrg.mixin';
import GeneralInfo from "@/pages/home/user/Index/CompanySellerInfo/GeneralInfo.vue";
import FeeDesc from "@/pages/home/user/Index/CompanySellerInfo/FeeDesc.vue";

export default {
    components: {
        FeeDesc,
        GeneralInfo,
        IUpload,
        BrandSelect,
        ViewDetail,
        Charge,
        Receipt,
    },
    mixins: [currentOrgMixin],
    data() {
        return {
            brandSizer: {
                current: 1,
            },
            options1: {
                disabledDate(date) {
                    if (!date) {
                        return false;
                    }
                    let s = dayjs(date);
                    let e = dayjs().add(-1, 'day');
                    return s.isBefore(e);
                },
            },
            columns: [
                {
                    title: '品牌名称',
                    key: 'name',
                    ellipsis: true,
                    render: (h, {row}) => {
                        return (
                            <Tooltip content={row.name}>
                                {row.name}
                            </Tooltip>
                        );
                    }
                },
                {
                    title: '经营级别',
                    key: 'qualification',
                    render(h, { row }) {
                        return h(
                            'span',
                            _.get(
                                $const.qualification[
                                    _.get(row, 'qualification')
                                ],
                                'display',
                            ) || '经销商',
                        );
                    },
                },
                {
                    title: '经营区域',
                    key: 'region',
                    render(h, { row }) {
                        return h(
                            'span',
                            _.get(row, 'region') || '全国',
                        );
                    },
                },
                {
                    title: '资质到期时间',
                    key: 'expireTime',
                    render: (h, { row }) => {
                        const expireTime = _.get(row, 'expireTime');
                        if (expireTime) {
                            return h('span', $util.format$Date(expireTime) || this.$emptyContent);
                        }

                        return h('span', this.$emptyContent);
                    },
                },
                {
                    title: '资质审核状态',
                    key: 'showStatus',
                    render: (h, { row }) => {
                        let s = _.get(row, 'showStatus');

                        if(s === 2) {
                            let text = _.get($const.showStatus[s], 'display');
                            console.log(row, 'row.status=2', s);
                            return (
                                <div class="flex-v-center">
                                    {text}
                                    <Tooltip placement="top" content={row.msg}>
                                        <a-icon type="question-circle" class="ml5" style="color: #feb14a;"/>
                                    </Tooltip>
                                </div>

                            );
                        }
                        return h(
                            'span',
                            _.get(
                                $const.showStatus[_.get(row, 'showStatus')],
                                'display',
                            ),
                        );
                    },
                },

                {
                    title: '操作',
                    width: 200,
                    align: 'center',
                    slot: 'action',
                },
            ],
            brandList: [],
            countInfo: {
                resCount: '',
                bidSuccessCount: '',
                totalAmount: '',
                priceAccurate: '',
                deliveryAccurate: '',
            },
            orgBrandId: '',
            amount: 0,
            amountOut: 0,
            amountFreeze: 0,
            options: {
                qualificationOptions: [...$const.options.agentLevel],
            },
            formInline: {
                qualification: '',
                qualificationExpireTime: '',
                fileList: [],
                qualificationRegion: '',
            },
            qualificationModel: false,
            ruleInline: {
                fileList: [
                    {
                        required: true,
                        message: '资质文件不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!value || !value.length) {
                                cb(new Error('资质文件不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                qualification: [
                    {
                        required: true,
                        message: '代理级别不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!value) {
                                cb(new Error('代理级别不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                qualificationPic: [
                    {
                        required: true,
                        message: '代理资质文件不能为空',
                        trigger: 'change',
                    },
                ],
                qualificationRegion: [
                    {
                        required: true,
                        message: '代理区域不能为空',
                        trigger: 'change',
                    },
                ],
                qualificationExpireTime: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                        validator: (rule, value, cb) => {
                            if (!value) {
                                rule.message = '不能为空';
                                cb(new Error('不能为空'));
                                return;
                            }

                            try {
                                let isDisabled =
                                    this.options1.disabledDate(value);
                                if (isDisabled) {
                                    rule.message = '不能选择今天之前时间';
                                    cb(new Error('不能选择今天之前时间'));
                                    return;
                                }
                            } catch (e) {
                                console.log(e, 'e');
                            }

                            cb();
                        },
                    },
                ],
            },
        };
    },
    computed: {
        org() {
            return _.get(this.$currentOrg, 'org') || {};
        },
        member() {
            return _.get(this.$currentOrg, 'member') || {};
        },
        pagePartsBrandList() {
            let newParts = [];
            let s = this.brandList.slice()
            while (s.length) {
                let a = [];
                while (s.length && a.length < 10) {
                    a.push(s.shift());
                }
                newParts.push(a);
            }

            return newParts;
        },
        pageBrandList() {
            return this.pagePartsBrandList[this.brandSizer.current - 1] || [];
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        handleOnChange(current) {
            this.brandSizer.current = current;
        },
        async getData() {
            //查询余额
            let amountRes = await $service.amount.detail();
            let amount = amountRes.data || {};
            this.amount = amount.amount || 0;
            this.amountOut = amount.amountOut || 0;
            this.amountFreeze = amount.fctFreeze || 0;
            //查询品牌
            let res = await $service.org.getSellerInfo({});
            let sellerInfo = res.data;
            this.brandSizer.current = 1;
            this.brandList = sellerInfo.orgBrandList;
            this.countInfo = sellerInfo;
        },
        async addBrand() {
            this.$refs.brandSelect.showModal();
        },
        async chargeShow() {
            this.$refs.charge.showModal();
        },
        //开发票
        kaifapiao() {
            this.$refs.receipt.showModal();
        },
        // 查看明细
        async viewDetail() {
            this.$refs.viewDetail.showModal();
        },
        //设置资质弹窗
        setQualification: function (row) {
            this.$refs.formInline.resetFields();
            this.orgBrandId = row.id;

            let picUrl = [];
            try {
                picUrl = JSON.parse(row.picUrl) || [];
            } catch (e) {}

            this.formInline.fileList = picUrl;
            this.formInline.qualification = '' + row.qualification;

            if (row.expireTime) {
                this.formInline.qualificationExpireTime = dayjs(
                    row.expireTime,
                ).format('YYYY-MM-DD HH:mm:ss');
            }
            this.formInline.qualificationRegion = row.region;
            this.qualificationModel = true;
        },
        async qualificationOk() {
            await new Promise((resolve) =>
                this.$refs.formInline.validate((valid) => valid && resolve()),
            );

            let uploadList = this.formInline.fileList || [];

            let fileList = [];
            for (let uploadFile of uploadList) {
                fileList.push({
                    name: uploadFile.name,
                    url: uploadFile.url,
                });
            }
            let param = {
                id: this.orgBrandId,
                qualification: this.formInline.qualification,
                expireTime: +this.formInline.qualificationExpireTime,
                picUrl: JSON.stringify(fileList),
                region: this.formInline.qualificationRegion,
            };

            await $service.orgBrand.update(param);
            this.$Message.info('操作成功');
            this.qualificationModel = false;
            await this.get$currentOrg();
            this.getData();
        },
        qualificationCancel() {
            this.qualificationModel = false;
        },
        async deleteBrand(row) {
            this.$Modal.confirm({
                title: '删除品牌',
                content: '是否删除 ' + row.name + ' 品牌？',
                footerhide: true,
                onOk: async () => {
                    await $service.orgBrand.delete({ id: row.id });
                    await this.get$currentOrg();
                    await this.getData();
                    // console.log("确认删除========" + JSON.stringify(row));
                },
            });
        },
    },
};
</script>
<style lang="less" scoped>
.info-item-wrapper {
    .title {
        margin-bottom: 12px;
    }

    .content {
        background: #f5f6fa;
    }
}
</style>
