<template>
    <div>
        <Alert v-if="data.length">已开票总金额{{ apiData.total }}元</Alert>
        <Table
            ref="table"
            class="fix-table-body-center"
            max-height="500"
            :columns="realColumns"
            :data="data"
        />
    </div>
</template>

<script>
export default {
    name: 'ReceiptHistory',
    props: {
        columns: {},
    },
    data() {
        return {
            apiData: {},
            data: [],
        };
    },
    computed: {
        realColumns() {
            return this.columns.filter((it) => it.type !== 'selection');
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        // 查看明细
        async getData() {
            let params = {
                operType: 4, //充值  4 消费
                pageSize: -1,
                invoiceStatus: 1,
            };
            let res = await $service.amount.amountLoglistFp(params);
            this.data = _.get(res, 'data.page.records') || [];
            this.apiData = _.get(res, 'data');
        },
    },
};
</script>
<style lang="less">
@red: #de1e32;

.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.vertical-center-modal .ivu-modal {
    top: 0;
}
</style>
