<template>
    <Form
        ref="formValidate"
        :model="formValidate"
        :rules="ruleValidate"
        :label-width="120"
    >
        <FormItem label="姓名" prop="name">
            <Input v-model="formValidate.name" clearable placeholder="姓名" />
        </FormItem>
        <FormItem label="默认登录身份" prop="defaultIdentity">
            <Select
                v-model="formValidate.defaultIdentity"
                class="wp100"
                placeholder="默认登录身份"
            >
                <Option
                    v-for="it of options.defaultIdentity"
                    :key="it.text"
                    :value="it.value"
                >
                    {{ it.text }}
                </Option>
            </Select>
        </FormItem>
        <FormItem>
            <Button class="mr10" type="primary" @click="handleConfirm">
                保存
            </Button>
            <Button class="mr10" @click="handleCancel"> 取消</Button>
        </FormItem>
    </Form>
</template>
<script>
import userInfoMixin from '@/mixins/userInfo.mixin';

export default {
    name: 'UpdateUserInfo',
    mixins: [userInfoMixin],
    props: {
        userData: {},
    },
    data() {
        console.log(this.userData, 'this.user...');
        return {
            options: {
                defaultIdentity: $const.options.defaultIdentity,
            },
            formValidate: {
                id: this.userData.id,
                defaultIdentity: String(this.userData.defaultIdentity),
                name: this.userData.name,
            },
            ruleValidate: {
                name: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                    },
                ],
                defaultIdentity: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                    },
                ],
            },
        };
    },
    methods: {
        handleCancel() {
            this.$emit('close');
        },
        async handleConfirm() {
            await new Promise((resolve) =>
                this.$refs.formValidate.validate((valid) => valid && resolve()),
            );
            console.log(this.formValidate, 'this.formValid...');
            let res = await $service.memeber.update({
                ...this.formValidate,
            });
            this.$Message.success(res.message);
            await this.get$userInfo();
            this.handleCancel();
        },
    },
};
</script>
