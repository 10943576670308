<template>
    <Modal v-model="show" title="查询明细" width="70%" footer-hide closable>
        <div class="view-detail-wrapper-tab">
            <Tabs v-model="tabType" @on-click="getData">
                <TabPane label="充值明细" name="1" />
                <TabPane label="费用使用明细" name="4" />
            </Tabs>
        </div>
        <div v-if="+tabType === 1" class="breadcrumb-link-item pointer active">
            <Table
                class="fix-table-body-center"
                height="400"
                :columns="tabType1Col"
                :data="tableData"
            />
        </div>
        <div v-if="+tabType === 4" class="breadcrumb-link-item pointer active">
            <Table
                class="fix-table-body-center"
                height="400"
                :columns="tabType4Col"
                :data="tableData"
            />
        </div>
    </Modal>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            tabType: '1',
            tableData: [],
            tableQueryParam: {
                operType: 1, //充值  4 消费
                pageSize: -1,
            },
            tabType1Col: [
                {
                    title: '日期',
                    key: 'created',
                    render(h, { row }) {
                        return h(
                            'span',
                            dayjs(_.get(row, 'created')).format('YYYY-MM-DD'),
                        );
                    },
                },
                {
                    title: '金额（元）',
                    key: 'operMoney',
                },
                {
                    title: '渠道',
                    key: 'payOrderType',
                },
            ],
            tabType4Col: [
                {
                    title: '需求号',
                    key: 'objectSn',
                    width: 180,
                },
                {
                    title: '姓名',
                    key: 'memberName',
                },
                {
                    title: '交易金额（元）',
                    key: 'objectAmount',
                },
                {
                    title: '交易日期',
                    key: 'created',
                    render(h, { row }) {
                        return h(
                            'span',
                            dayjs(_.get(row, 'created')).format('YYYY-MM-DD'),
                        );
                    },
                },
                {
                    title: '服务费用（元）',
                    key: 'operMoney',
                },
                {
                    title: '类型',
                    key: 'typeMsg',
                },
            ],
        };
    },
    methods: {
        // 查看明细
        async getData() {
            let res = await $service.amount.amountLoglist({
                operType: this.tabType, //充值  4 消费
                pageSize: -1,
            });

            this.tableData = _.get(res, 'data.records') || [];
        },
        showModal() {
            this.tabType = '1';
            this.getData();
            this.show = true;
        },
        closeModal() {
            this.show = false;
        },
    },
};
</script>
<style lang="less" scoped>
@red: #de1e32;
.view-detail-wrapper {
    /deep/ &-tab {
        .ivu-tabs-bar {
            border: none;

            .ivu-tabs-tab {
                &:hover {
                    color: @red;
                }

                &-active,
                &-focused {
                    color: @red;
                }
            }

            .ivu-tabs-ink-bar {
                background-color: @red;
            }
        }
    }
}
</style>
