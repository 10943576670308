<template>
    <div>
        <div class="user-banner mb10">
            <img
                class="wp100 vertical-mid"
                src="../../../assets/img/banner/user.webp"
                alt=""
            />
        </div>
        <div class="flex affix-wrapper">
            <Affix :offset-top="100">
                <div class="breadcrumb-wrapper bg-white pd20">
                    <div class="general">总览</div>
                    <div>
                        <div
                            v-for="it of listItems"
                            :key="it.id"
                            class="breadcrumb-link-item pointer"
                            :class="{ active: active === it.id }"
                            @click="handleClickItem(it)"
                        >
                            {{ it.display }}
                        </div>
                    </div>
                </div>
            </Affix>
            <div class="ml20" style="flex-grow: 1">
                <div>
                    <ICard anchor="_uiq_personal" title="个人信息" order="1">
                        <Personal
                            v-if="isCurrentOrgReady"
                            ref="personal"
                            class="mb10"
                        />
                    </ICard>
                    <ICard
                        anchor="_uiq_companyInfo"
                        title="企业基础信息"
                        order="2"
                    >
                        <CompanyInfo v-if="isCurrentOrgReady" class="mb10" />
                    </ICard>
                    <ICard
                        anchor="_uiq_companyPersonInfo"
                        title="企业人员信息"
                        order="3"
                    >
                        <CompanyPersonInfo
                            v-if="isCurrentOrgReady"
                            class="mb10"
                            :operation-type="2"
                        />
                    </ICard>
                    <ICard
                        anchor="_uiq_companyBuyerInfo"
                        title="企业买家身份信息"
                        order="4"
                    >
                        <CompanyBuyerInfo
                            v-if="isCurrentOrgReady"
                            class="mb10"
                        />
                    </ICard>
                    <ICard
                        anchor="_uiq_companySellerInfo"
                        title="卖家身份信息"
                        order="5"
                    >
                        <CompanySellerInfo
                            v-if="isCurrentOrgReady"
                            class="mb10"
                        />
                    </ICard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Personal from './Index/Personal.vue';
import CompanyInfo from './Index/CompanyInfo.vue';
import CompanyPersonInfo from './Index/CompanyPersonInfo.vue';
import CompanyBuyerInfo from './Index/CompanyBuyerInfo.vue';
import CompanySellerInfo from './Index/CompanySellerInfo.vue';
import ICard from '../../../components/ICard.vue';
import userInfoMixin from '@/mixins/userInfo.mixin';
import currentOrgMixin from '@/mixins/currentOrg.mixin';

const MEMO_KEY = '__active.user.index';
const handleScrollWrapper = _.debounce((checkIds = [], cb) => {
    let finalIdInView = checkIds[0];
    for (let id of checkIds) {
        let top = document.getElementById(id).getBoundingClientRect().top;
        if (top < 90 + 40) {
            finalIdInView = id;
        }
    }

    cb(finalIdInView);
}, 2e2);
export default {
    name: 'Index',
    components: {
        ICard,
        CompanySellerInfo,
        CompanyBuyerInfo,
        CompanyPersonInfo,
        CompanyInfo,
        Personal,
    },
    mixins: [userInfoMixin, currentOrgMixin],
    data() {
        return {
            active: '_uiq_personal',
            listItems: [
                { display: '个人信息', id: '_uiq_personal' },
                { display: '企业基础信息', id: '_uiq_companyInfo' },
                { display: '企业人员信息', id: '_uiq_companyPersonInfo' },
                { display: '买家身份信息', id: '_uiq_companyBuyerInfo' },
                { display: '卖家身份信息', id: '_uiq_companySellerInfo' },
            ],
        };
    },
    computed: {
        listItemIds() {
            return this.listItems.map((it) => it.id);
        },
        isCurrentOrgReady() {
            return this.$currentOrg.member || this.$currentOrg.memberAddress;
        },
    },
    beforeDestroy() {
        document.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.get$currentOrg();
        // 默认进入
        let defaultActive = this.$route.query.defaultActive;
        if (this.listItemIds.includes(defaultActive)) {
            this.active = defaultActive;
        } else {
            this.active = this.getMemo();
        }
        setTimeout(() => {
            this.handleClickItem({ id: this.active });
            document.addEventListener('scroll', this.handleScroll);
        }, 3e2);
    },
    methods: {
        memoCurrent(value) {
            sessionStorage.setItem(MEMO_KEY, value);
        },
        getMemo() {
            return sessionStorage.getItem(MEMO_KEY) || this.listItemIds[0];
        },
        handleClickItem(item) {
            this.active = item.id;

            try {
                document.getElementById(item.id).scrollIntoViewIfNeeded();
                this.memoCurrent(item.id);
            } catch (e) {
                console.log(e);
            }
        },
        handleScroll() {
            handleScrollWrapper(this.listItemIds, (id) => {
                this.active = id;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.affix-wrapper {
    /deep/ .ivu-affix {
        z-index: unset;
    }
}
</style>
<style lang="less" scoped>
.breadcrumb-wrapper {
    height: 350px;
    .general {
        line-height: 36px;
        color: rgba(0, 0, 0, 0.45);
        padding: 0 10px;
    }
    .breadcrumb-link-item {
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        padding-left: 10px;
        width: 180px;

        &.active {
            background: #ffeef1;
        }
    }
}
</style>
