<template>
    <div class="mt20">
        <div class="info-item-wrapper">
            <div class="title bold">
                <Icon type="ios-locate-outline" color="#de1e32" />
                默认收货地址
            </div>
            <div class="content pd24">
                <Form
                    ref="formValidate"
                    :model="formValidate"
                    :rules="ruleValidate"
                    :label-width="120"
                >
                    <FormItem label="地址" prop="citySelectValue">
                        <ICity
                            v-if="isEdit"
                            v-model="formValidate.citySelectValue"
                        />
                        <span v-else>{{ memberAddressView }}</span>
                    </FormItem>
                    <FormItem label="详细地址" prop="detailAddress">
                        <Input
                            v-if="isEdit"
                            v-model="formValidate.detailAddress"
                            placeholder="地址"
                            clearable
                        />
                        <span v-else>{{ memberAddress.detailAddress }}</span>
                    </FormItem>
                    <FormItem label="收货人" prop="name">
                        <Input
                            v-if="isEdit"
                            v-model="formValidate.name"
                            placeholder="收货人"
                            clearable
                        />
                        <span v-else>{{ memberAddress.name }}</span>
                    </FormItem>
                    <FormItem label="收货电话" prop="phoneNumber">
                        <Input
                            v-if="isEdit"
                            v-model="formValidate.phoneNumber"
                            placeholder="收货电话"
                            :maxlength="11"
                            clearable
                        />
                        <span v-else>{{ memberAddress.phoneNumber }}</span>
                    </FormItem>

                    <FormItem>
                        <template v-if="isEdit">
                            <Button
                                class="mr10"
                                type="primary"
                                @click="handleSubmit"
                            >
                                保存
                            </Button>
                            <Button @click="handleCancel"> 取消</Button>
                        </template>
                        <Button
                            v-if="isView"
                            type="primary"
                            @click="handleToEdit"
                        >
                            编辑
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import ICity from '@/components/ICity.vue';
import cityUtil from '@/util/cityUtil';
import currentOrgMixin from '@/mixins/currentOrg.mixin';

export default {
    components: { ICity },
    mixins: [currentOrgMixin],
    data() {
        return {
            editType: 'view',
            memberAddress: {
                citySelectValue: [],
                detailAddress: '',
                name: '',
                phoneNumber: '',
            },
            formValidate: {
                citySelectValue: [],
                detailAddress: '',
                name: '',
                phoneNumber: '',
            },
            ruleValidate: {
                citySelectValue: [
                    {
                        required: true,
                        message: '请输入收货地址',
                        trigger: 'blur',
                        validator: (rule, value, cb) => {
                            if (!this.formValidate.citySelectValue.length) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                detailAddress: [
                    {
                        required: true,
                        message: '请输入地址',
                        trigger: 'blur',
                    },
                ],
                name: [
                    {
                        required: true,
                        message: '请输入收货人',
                        trigger: 'blur',
                    },
                ],
                phoneNumber: [
                    {
                        required: true,
                        message: '请输入收货电话',
                        trigger: 'blur',
                        validator: (rule, value, cb) => {
                            if (!value) {
                                rule.message = '不能为空';
                                cb(new Error('不能为空'));
                                return;
                            }
                            if (11 != value.length) {
                                rule.message = '手机号长度11位';
                                cb(new Error('手机号长度11位'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
            },
        };
    },
    computed: {
        memberAddressView() {
            let [province, city, region] =
                _.get(this, 'memberAddress.citySelectValue') || [];
            if (!province || !city || !region) {
                return '-';
            }

            let provinceText = _.get(cityUtil.getItemByCode(province), 'text');
            let cityText = _.get(cityUtil.getItemByCode(city), 'text');
            let regionText = _.get(cityUtil.getItemByCode(region), 'text');
            return `${provinceText}-${cityText}-${regionText}`;
        },
        isView() {
            return this.editType === 'view';
        },
        isEdit() {
            return this.editType === 'edit';
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        handleToEdit() {
            this.editType = 'edit';
        },
        async handleCancel() {
            this.handleReset();
            await this.get$currentOrg();
            await this.loadData();
        },
        async loadData() {
            let memberAddress = _.get(this.$currentOrg, 'memberAddress') || {};
            let { province, city, region } = memberAddress;
            if (province && city && region) {
                memberAddress.citySelectValue = [+province, +city, +region];
            } else {
                memberAddress.citySelectValue = [];
            }

            this.memberAddress = memberAddress;
            this.formValidate = _.cloneDeep(memberAddress);

            this.editType = 'view';
        },
        async handleSubmit() {
            await new Promise((resolve) =>
                this.$refs.formValidate.validate((valid) => valid && resolve()),
            );

            let [province, city, region] = this.formValidate.citySelectValue;

            let params = {
                province,
                city,
                region,
                name: this.formValidate.name,
                detailAddress: this.formValidate.detailAddress,
                phoneNumber: this.formValidate.phoneNumber,
                defaultStatus: 1, // 默认地址: 创建默认 , 更新也默认
            };
            if (this.memberAddress.id) {
                params.id = this.memberAddress.id;
                await $service.memberReceiveAddress.update(params);
            } else {
                await $service.memberReceiveAddress.create(params);
            }

            await this.get$currentOrg();
            await this.loadData();
        },
        handleReset() {
            this.$refs.formValidate.resetFields();
        },
    },
};
</script>
