<template>
    <div class="user-personal">
        <div>
            <div class="mt20">
                <Row>
                    <Col :span="15">
                        <div class="mb10 flex-v-center">
                            <span class="field-title c-gray">用户名:</span>
                            <span class="field-text">{{
                                $userInfo.username
                            }}</span>
                            <template v-if="userStatusStr">
                                <!--  拒绝情况提示   -->
                                <Tooltip
                                    v-if="$userInfo.status === 0"
                                    content="企业管理员还没有给您授权，请联系企业管理员进行操作权限授权"
                                    placement="right"
                                >
                                    <Tag class="ml10" color="blue">{{
                                        userStatusStr
                                    }}</Tag>
                                </Tooltip>
                                <Tag v-else class="ml10" color="blue">{{
                                    userStatusStr
                                }}</Tag>
                            </template>
                        </div>
                        <div class="mb10 flex">
                            <span class="field-title c-gray">姓名:</span>
                            <span class="field-text">{{ $userInfo.name }}</span>
                        </div>
                        <div class="mb10 flex">
                            <span class="field-title c-gray">手机号:</span>
                            <span class="field-text">{{
                                $userInfo.phone
                            }}</span>
                        </div>
                        <div class="mb10 flex">
                            <span class="field-title c-gray">买家履约率:</span>
                            <span class="field-text"
                                >{{
                                    $userInfo.performanceAccurate * 100
                                }}%</span
                            >
                        </div>
                        <div class="mb10 flex">
                            <span class="field-title c-gray">
                                默认登录身份 :
                            </span>
                            <span class="field-text">
                                {{ defaultIdentityData }}
                            </span>
                        </div>
                        <!--                        <div class="mb10 flex">
                            <span class="field-title c-gray">所属企业:</span>
                            <span class="field-text">{{
                                $userInfo.orgName
                            }}</span>
                            &lt;!&ndash;   企业认证信息   &ndash;&gt;
                            <div v-if="$userInfo.orgName && statusApproved " class="ml5 relative">
                                <span
                                    class="dot-toast"
                                    v-if="currentOrg.status - 2 === 0"
                                    @click="handleToast"
                                ></span>
                                <img
                                    style="height: 24px"
                                    class="vertical-mid"
                                    :src="statusApproved.img"
                                    :alt="statusApproved.img"
                                />
                            </div>
                        </div>-->
                        <!--                        <div>-->
                        <!--                            <Tag v-if="userStatusStr" color="blue">{{userStatusStr}}</Tag>-->
                        <!--                            &lt;!&ndash; <Tag v-if="orgType" color="blue">{{ orgType }}</Tag>&ndash;&gt;-->
                        <!--                        </div>-->
                    </Col>
                    <Col :span="9">
                        <div class="flex-end">
                            <!--                            <Button
                                v-if="$userInfo.orgId && $userInfo.type === 2"
                                class="mr10"
                                @click="transfer"
                            >
                                转让管理员
                            </Button>
                            <Button
                                v-if="$userInfo.orgId && $userInfo.type === 3"
                                class="mr10"
                                @click="out"
                            >
                                退出企业
                            </Button>-->
                            <!--                            <Button type="primary" @click="updatePassword">-->
                            <!--                                修改密码-->
                            <!--                            </Button>-->

                            <Button type="primary" @click="updateUserInfo">
                                编辑
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>

        <!--        <div>
            <Modal
                v-model="transferModal"
                title="转让管理员"
                :closable="false"
                :footer-hide="true"
            >
                <Select
                    v-model="transUid"
                    clearable
                    filterable
                    :loading="loading1"
                >
                    <Option
                        v-for="(option, index) in options1"
                        :key="index"
                        :value="option.value"
                        >{{ option.label }}
                    </Option>
                </Select>

                <div class="flex-end flex-v-center mt20">
                    <Button class="mr10" @click="transCancel">取消</Button>
                    <Button type="primary" @click="transOk">确定</Button>
                </div>
            </Modal>
        </div>

        <div>
            <Modal
                v-model="updatePasswordModel"
                title="修改密码"
                :closable="false"
                :footer-hide="true"
            >
                <UpdatePassword
                    v-if="updatePasswordModel"
                    @close="updatePasswordModel = false"
                />
            </Modal>
        </div>-->

        <div>
            <Modal
                v-model="updateUserInfoModel.show"
                title="修改个人信息"
                :closable="false"
                :footer-hide="true"
            >
                <UpdateUserInfo
                    v-if="updateUserInfoModel.show"
                    :user-data="updateUserInfoModel.data"
                    @close="updateUserInfoModel.show = false"
                />
            </Modal>
        </div>
    </div>
</template>

<script>
import UpdatePassword from './Personal/UpdatePassword.vue';
import userInfoMixin from '@/mixins/userInfo.mixin';
import UpdateUserInfo from './Personal/UpdateUserInfo.vue';

let sc = {
    0: {
        style: { color: '#0a5495' },
        text: '认证中',
        img: require('@/assets/img/approve/process.png'),
    },
    1: {
        style: { color: '#00c395' },
        text: '认证通过',
        img: require('@/assets/img/approve/ok.png'),
    },
    2: {
        style: { color: '#999999' },
        text: '认证未通过',
        img: require('@/assets/img/approve/fail.png'),
    },
};
export default {
    components: { UpdateUserInfo, UpdatePassword },
    mixins: [userInfoMixin],
    data() {
        return {
            transferModal: false,
            updatePasswordModel: false,
            transUid: '',
            personalList: [],
            loading1: false,
            options1: [],
            updatePasswordForm: {
                oldPassword: '',
                password: '',
                confirmPassword: '',
            },
            currentOrg: {},
            updateUserInfoModel: {
                show: false,
                data: {},
            },
        };
    },
    computed: {
        defaultIdentityData() {
            return (
                $const.optionsMap.defaultIdentity[
                    _.get(this.$userInfo, 'defaultIdentity')
                ] || '--'
            );
        },
        statusApproved() {
            let status = _.get(this.currentOrg, 'org.status');
            if (status) {
                return sc[status];
            }
        },
        orgType() {
            let type = _.get(this.currentOrg, 'org.type');
            if (type) {
                return $util.getOptName(type, $const.options.orgType);
            }
        },
    },
    mounted() {
        this.get$currentOrg();
        this.transferSelect();
    },
    methods: {
        async get$currentOrg(params = {}) {
            await this.$nextTick();
            let res = await $service.org.getCurrentOrg(params);
            let data = _.get(res, 'data') || {};
            this.currentOrg = data;
            return data;
        },
        handleToast() {
            let status = _.get(this.$currentOrg, 'org.status');
            if (status - 2 === 0) {
                let content =
                    _.get(this.$currentOrg, 'org.rejectMark') ||
                    this.$emptyContent;
                this.$toast.warning('资质认证失败原因', content, {
                    duration: 0,
                    closable: true,
                });
            }
        },
        out() {
            if (this.$userInfo.type === 2) {
                this.$Message.error('管理员用户请转让管理员身份后退出企业');
                return;
            }
            this.$Modal.confirm({
                title: '退出',
                content: '确定退出吗?',
                onOk: async () => {
                    let res = await $service.memeber.deleteFromOrg({
                        id: this.$userInfo.id,
                    });
                    this.$Message.success(res.message);
                    //刷新机构信息
                    location.reload();
                },
            });
        },
        transfer() {
            this.transferModal = true;
        },
        updatePassword() {
            this.updatePasswordModel = true;
        },
        updateUserInfo() {
            this.updateUserInfoModel = {
                show: true,
                data: _.cloneDeep(this.$userInfo),
            };
        },
        async transOk() {
            if (!this.transUid) {
                return;
            }
            await $service.memeber.transferAdmin({ id: this.transUid });
            this.$Message.info('操作成功');
            this.transCancel();
            location.reload();
        },
        transCancel() {
            this.transferModal = false;
        },

        async transferSelect(keywords) {
            let params = {
                pageSize: -1,
            };

            if (keywords) {
                params.username = keywords;
            }

            this.loading1 = true;

            try {
                let res = await $service.memeber.listByCondition(params);
                let userList = _.get(res, 'data.records') || [];
                const list = userList.map((item) => ({
                    value: item.id,
                    label: item.username,
                }));
                this.options1 = keywords
                    ? list.filter(
                          (item) =>
                              item.label
                                  .toLowerCase()
                                  .indexOf(keywords.toLowerCase()) > -1,
                      )
                    : list;
            } finally {
                this.loading1 = false;
            }
        },
    },
};
</script>
<style lang="less" scoped>
.field-title {
    margin-right: 10px;
}
.user-personal {
    /deep/ .ivu-tooltip-inner {
        white-space: pre-wrap;
    }
}
</style>
