<template>
    <div>
        <HasOrg v-if="hasOrg" />
        <NotHasOrg v-if="!hasOrg" />
        <DefaultInvoice />
    </div>
</template>
<script>
import HasOrg from './CompanyInfo/HasOrg.vue';
import NotHasOrg from './CompanyInfo/NotHasOrg.vue';
import currentOrgMixin from '@/mixins/currentOrg.mixin';
import DefaultInvoice from '@/pages/home/user/Index/CompanyBuyerInfo/DefaultInvoice.vue';

export default {
    components: {
        DefaultInvoice,
        NotHasOrg,
        HasOrg,
    },
    mixins: [currentOrgMixin],
    computed: {
        hasOrg() {
            return !!_.get(this.$currentOrg, 'org');
        },
    },
};
</script>
