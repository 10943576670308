<template>
    <div>
        <div v-show="current === 0">
            <Alert v-if="apiData.total"
                >未开票总金额{{
                    apiData.total
                }}元,总金额满100元可申请开票</Alert
            >
            <Table
                ref="table"
                class="fix-table-body-center"
                max-height="500"
                :columns="columns"
                :data="data"
                @on-selection-change="handleSelect"
            />
        </div>
        <div v-if="current === 1">
            <Form
                ref="formValidate"
                :model="formItem"
                :rules="ruleValidate"
                :label-width="80"
            >
                <FormItem label="抬头类型">
                    <RadioGroup v-model="formItem.type">
                        <Radio label="2">普通发票</Radio>
                        <Radio label="1">专用发票</Radio>
                    </RadioGroup>
                </FormItem>
                <div v-if="formItem.type === '2'">
                    <FormItem label="公司名称" prop="invoiceTitle">
                        <Input
                            v-model="formItem.invoiceTitle"
                            placeholder="公司名称"
                            clearable
                        />
                    </FormItem>
                    <FormItem label="公司税号" prop="invoiceNo">
                        <Input
                            v-model="formItem.invoiceNo"
                            placeholder="公司税号"
                            clearable
                        />
                    </FormItem>
                    <FormItem label="总额">
                        <Input
                            disabled
                            :value="totalAmount"
                            placeholder="总额"
                        />
                    </FormItem>
                    <FormItem label="邮箱" prop="email">
                        <Input
                            v-model="formItem.email"
                            clearable
                            placeholder="邮箱"
                        />
                    </FormItem>
                </div>
                <div v-if="formItem.type === '1'">
                    <FormItem label="公司名称" prop="invoiceTitle">
                        <Input
                            v-model="formItem.invoiceTitle"
                            placeholder="公司名称"
                            clearable
                        />
                    </FormItem>
                    <FormItem label="公司税号" prop="invoiceNo">
                        <Input
                            v-model="formItem.invoiceNo"
                            placeholder="公司税号"
                            clearable
                        />
                    </FormItem>
                    <FormItem label="地址" prop="addr">
                        <Input
                            v-model="formItem.addr"
                            placeholder="地址"
                            clearable
                        />
                    </FormItem>
                    <FormItem label="电话" prop="phone">
                        <Input
                            v-model="formItem.phone"
                            placeholder="电话"
                            clearable
                        />
                    </FormItem>
                    <FormItem label="开户行" prop="bank">
                        <Input
                            v-model="formItem.bank"
                            placeholder="开户行"
                            clearable
                        />
                    </FormItem>
                    <FormItem label="账号" prop="bankAccount">
                        <Input
                            v-model="formItem.bankAccount"
                            placeholder="账号"
                            clearable
                        />
                    </FormItem>
                    <FormItem label="总额">
                        <Input
                            disabled
                            :value="totalAmount"
                            placeholder="总额"
                        />
                    </FormItem>
                    <FormItem label="邮箱" prop="email">
                        <Input
                            v-model="formItem.email"
                            clearable
                            placeholder="邮箱"
                        />
                    </FormItem>
                </div>
            </Form>
        </div>

        <div class="mt10">
            <div v-if="current === 0" class="flex-end">
                <Alert
                    :style="{ opacity: selected.length ? 1 : 0 }"
                    type="warning"
                    show-icon
                >
                    {{ selected.length }}个清单,共{{ totalAmount }}元
                </Alert>
                <Button
                    type="primary"
                    class="mr10 ml10"
                    :disabled="!data.length"
                    @click="handleSelectAll"
                    >全选</Button
                >
                <Button
                    type="primary"
                    :disabled="!selected.length"
                    @click="current = 1"
                    >下一步</Button
                >
            </div>
            <div v-if="current === 1" class="flex-end">
                <Button class="mr10" @click="current = 0">上一步</Button>
                <Button type="primary" @click="handleSubmit">提交</Button>
            </div>
        </div>
    </div>
</template>

<script>
import np from 'number-precision';
export default {
    name: 'ToReceipt',
    props: {
        columns: {},
        currentOrg: {},
    },
    data() {
        return {
            current: 0,
            apiData: {},
            data: [],
            formItem: {
                type: '1',
            },
            selected: [],
            ruleValidate: {
                invoiceTitle: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                invoiceNo: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                invoiceInfo: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                email: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                addr: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                bank: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                bankAccount: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    computed: {
        totalAmount() {
            let arr = this.selected.map((it) => +it.operMoney || 0);
            return np.plus(...arr);
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        handleSelect(selected) {
            this.selected = selected;
        },
        // 查看明细
        async getData() {
            let params = {
                operType: 4, //充值  4 消费
                pageSize: -1,
                invoiceStatus: 0,
            };
            let res = await $service.amount.amountLoglistFp(params);
            // {
            //         "id": 556,
            //         "amountId": 5,
            //         "operMoney": 0.02,
            //         "operPre": 1,
            //         "operAfter": 0.98,
            //         "operType": 4,
            //         "created": 1707964179000,
            //         "typeMsg": "交易服务费",
            //         "remarks": "中标扣除",
            //         "uname": "test25",
            //         "invoiceStatus": 0,
            //         "uid": 26,
            //         "orgId": 5,
            //         "objectId": 63,
            //         "objectSn": "N0016240215000001",
            //         "publishIndex": 1,
            //         "objectAmount": 2
            // }
            this.data = _.get(res, 'data.page.records') || [];
            this.apiData = _.get(res, 'data');
            //初始化表单中的发票信息
            let org = _.get(this.currentOrg, 'org') || {};
            this.formItem.invoiceTitle = org.invoiceName;
            this.formItem.invoiceNo = org.invoiceSn;
            this.formItem.addr = org.invoiceAddr;
            this.formItem.phone = org.invoicePhone;
            this.formItem.bank = org.invoiceBank;
            this.formItem.bankAccount = org.invoiceAccount;
        },
        handleSelectAll() {
            this.$refs.table.selectAll(true);
        },
        async handleSubmit() {
            await new Promise((resolve) =>
                this.$refs.formValidate.validate((valid) => valid && resolve()),
            );
            console.log(this.selected, 'ggg', this.formItem);

            let params = {
                type: this.formItem.type,
                invoiceTitle: this.formItem.invoiceTitle,
                invoiceNo: this.formItem.invoiceNo,
                invoiceInfo: this.formItem.invoiceInfo,
                email: this.formItem.email,
                amountLogIds: this.selected.map((it) => it.id),

                // 专票
                addr: this.formItem.addr,
                phone: this.formItem.phone,
                bank: this.formItem.bank,
                bankAccount: this.formItem.bankAccount,
            };

            let res = await $service.amount.invoicing(params);
            console.log(res, 'res...');
            this.$toast.info(
                '提示',
                '研小助已收到您的发票申请，将于10个工作日内开具电子发票并发送您邮箱，请关注查收',
                {
                    duration: 5,
                    onClose: () => {
                        this.$emit('close');
                    },
                },
            );
        },
    },
};
</script>
<style lang="less">
@red: #de1e32;

.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.vertical-center-modal .ivu-modal {
    top: 0;
}
</style>
