<template>
    <Modal
        v-model="show"
        title="发票"
        width="70%"
        footer-hide
        closable
        class-name="vertical-center-modal"
        :transfer="false"
    >
        <div v-if="show" class="vertical-center-modal-tab">
            <Tabs v-model="tabName">
                <TabPane label="未开票明细" name="toReceipt" />
                <TabPane label="开票历史" name="receiptHistory" />
            </Tabs>
            <ToReceipt
                v-if="tabName === 'toReceipt'"
                :columns="shareColumns"
                :currentOrg="currentOrg"
                @close="closeModal"
            />
            <ReceiptHistory
                v-if="tabName === 'receiptHistory'"
                :columns="shareColumns"
            />
        </div>
    </Modal>
</template>

<script>
import ToReceipt from './Receipt/ToReceipt.vue';
import ReceiptHistory from './Receipt/ReceiptHistory.vue';

export default {
    name: 'Receipt',
    components: { ReceiptHistory, ToReceipt },
    props: {
        currentOrg: {},
    },
    data() {
        return {
            shareColumns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center',
                },
                {
                    title: '需求编号',
                    key: 'objectSn',
                },
                {
                    title: '订单金额(元)',
                    key: 'objectAmount',
                },
                {
                    title: '交易日期',
                    key: 'created',
                    render: (h, { row }) => {
                        return h(
                            'span',
                            $util.format$Date(_.get(row, 'created')) ||
                                this.$emptyContent,
                        );
                    },
                },
                {
                    title: '交易服务费(元)',
                    key: 'operMoney',
                },
                // {
                //     title: '延期供货服务扣费(元)',
                //     // title: '延期扣费(元)',
                //     key: 'delayedAmount',
                // },
            ],
            tabName: 'toReceipt',
            show: false,
        };
    },
    methods: {
        showModal() {
            this.show = true;
        },
        closeModal() {
            this.show = false;
        },
    },
};
</script>
<style lang="less" scoped>
@red: #de1e32;

.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .ivu-modal {
        top: 0;
    }

    /deep/ &-tab {
        .ivu-tabs-bar {
            border: none;

            .ivu-tabs-tab {
                &:hover {
                    color: @red;
                }

                &-active,
                &-focused {
                    color: @red;
                }
            }

            .ivu-tabs-ink-bar {
                background-color: @red;
            }
        }
    }
}
</style>
