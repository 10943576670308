<template>
    <div>
        <div class="mt20">
            <DefaultAddr />
        </div>
    </div>
</template>

<script>
import DefaultAddr from './CompanyBuyerInfo/DefaultAddr.vue';

export default {
    components: { DefaultAddr },
};
</script>
<style lang="less" scoped>
.general-info {
    .general-info-item {
        margin-right: 30px;
    }
}

.info-item-wrapper {
    .title {
        margin-bottom: 12px;
    }
    .content {
        background: #f5f6fa;
    }
}

.title-item {
    width: 100px;
    text-align: right;
}
</style>
