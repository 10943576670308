<template>
    <Modal
        v-model="show"
        title="添加品牌"
        width="1200"
        footer-hide
        @on-close="closeModal"
    >
        <div class="pointer active">
            <div class="bold c-black mb5 flex-v-center">
                <span style="width: 2px; height: 12px" class="mr5 bg-red"></span
                >已选品牌
            </div>
            <div class="selected-wrap flex mb20">
                <template v-if="selectBrandList.length">
                    <div
                        v-for="item of selectBrandList"
                        :key="item.brandId"
                        class="selected-item bold c-black"
                    >
                        <Tag closable @on-close="handleClose(item)">{{
                            item.name
                        }}</Tag>
                    </div>
                </template>
                <div v-else class="selected-item c-black">暂无数据</div>
            </div>
        </div>
        <div class="pointer active">
            <div class="bold c-black mb5 flex-v-center">
                <span style="width: 2px; height: 12px" class="mr5 bg-red"></span
                >增加品牌
            </div>

            <div class="letters-wrap flex">
                <div class="letter-item-title tc c-black">所有品牌:</div>
                <div
                    v-for="(item, index) of letters"
                    :key="index"
                    class="letter-item tc bold c-black"
                    :class="{ active: item.checked }"
                    @click="handleCheck(item)"
                >
                    {{ item.value }}
                </div>
            </div>

            <div class="check-wrapper">
                <div class="mt10 mb20 flex-v-center">
                    <Input
                        v-model="searchValue"
                        class="w200 mr10"
                        placeholder="搜索品牌名称"
                        clearable
                    />
                    <!--                    <Button type="primary" @click="handleSearch">-->
                    <!--                        搜索-->
                    <!--                    </Button>-->
                </div>
                <Checkbox
                    v-for="(option, index) of currentCheckedOpts"
                    v-show="filterSearchValue(option)"
                    :key="index"
                    v-model="option.checked"
                    :data-id="option.id"
                    :label="option.id"
                >
                    {{ option.name }}
                </Checkbox>
            </div>

            <div slot="footer" class="flex-v-center mt20 flex-end">
                <Button class="mr10" @click="closeModal">取消</Button>
                <Button type="primary" @click="brandOk">确定</Button>
            </div>
        </div>
    </Modal>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            letters: _.range(65, 91).map((it) => ({
                value: String.fromCharCode(it),
                checked: false,
            })),
            allBrandData: [],
            disabledIds: [],
            orgBrandList: [],
            searchValue: '',
        };
    },
    computed: {
        selectBrandList() {
            return this.allBrandData.filter((it) => it.checked);
        },
        currentCheckedOpts() {
            let allF = this.letters
                .filter((it) => it.checked)
                .map((it) => it.value);
            if (!allF.length) {
                return this.allBrandData;
            }
            return this.allBrandData.filter((it) =>
                allF.includes(it.firstLetter),
            );
        },
    },
    methods: {
        async getBrandList() {
            //查询品牌
            let res = await $service.org.getSellerInfo({});
            let brandList = _.get(res, 'data.orgBrandList') || [];
            this.orgBrandList = brandList;
            let brandIds = brandList.map((it) => it.brandId);
            this.disabledIds = brandIds; // 已有禁用
            return brandIds;
        },
        async getData(isInit = false) {
            if (isInit) {
                this.letters[0].checked = true; // 默认第一个
            }
            //查询品牌
            let brandIds = await this.getBrandList();

            let res = await $service.brand.list({ pageSize: -1 });
            let allBrandData = _.get(res, 'data.records') || [];
            for (let it of allBrandData) {
                if (brandIds.includes(it.id)) {
                    it.checked = true;
                }
            }

            this.allBrandData = allBrandData;
        },
        showModal() {
            this.getData(true);
            this.show = true;
        },
        closeModal() {
            this.show = false;
        },
        handleCheck(item) {
            if (item.checked) {
                item.checked = false;
                return;
            }

            for (let it of this.letters) {
                it.checked = item.value === it.value;
            }
        },
        async brandOk() {
            if (!this.selectBrandList.length) {
                this.$Message.error('请选择品牌');
                return;
            }

            await this.getBrandList(); // update new brandList
            let brandIds = this.selectBrandList.map((it) => it.id);
            // 同步删除
            for (let it of this.orgBrandList) {
                if (!brandIds.includes(it.brandId)) {
                    await $service.orgBrand.delete({ id: it.id });
                }
            }

            await $service.orgBrand.save({ brandIds: brandIds });
            this.$Message.info('操作成功');
            this.closeModal();
            this.$emit('update');
            // this.getData();
        },
        async handleClose(item) {
            item.checked = false;
        },
        handleSearch() {
            console.log(
                this.searchValue,
                'search....',
                this.currentCheckedOpts,
            );
        },
        filterSearchValue(it) {
            let searchValue = (this.searchValue || '').trim().toLowerCase();
            if (!searchValue) {
                return true;
            }
            let name = it.name || '';
            // 全匹配
            return name.toLowerCase().includes(searchValue);
        },
    },
};
</script>
<style lang="less" scoped>
.selected-wrap {
    flex-wrap: wrap;
    max-height: 16vh;
    overflow-y: scroll;
    .selected-item {
        margin: 4px 4px 4px 0;

        &:first-of-type {
            margin-left: 0;
        }
    }
}

.letters-wrap {
    flex-wrap: wrap;
    .letter-item-title {
        height: 20px;
        line-height: 20px;
        padding: 2px;
        background: none;
        margin: 4px;
        margin-left: 0;
    }

    .letter-item {
        width: 20px;
        height: 20px;
        line-height: 20px;
        padding: 2px;
        background: none;
        margin: 4px;

        &.active {
            color: white;
            background: red;
        }
    }
}
.check-wrapper {
    height: 36vh;
    overflow-y: scroll;
    .ivu-checkbox-wrapper {
        width: 22%;
    }
}
</style>
