<template>
    <div class="flex-center general-info">
        <div class="general-info-item">
            <div class="general-info-item-tip bold">
                {{ countInfo.resCount }}
            </div>
            企业响应的需求总数量
        </div>
        <div class="general-info-item">
            <div class="general-info-item-tip bold">
                {{ countInfo.bidSuccessCount }}
            </div>
            抢单成功的需求数量
        </div>
        <div class="general-info-item">
            <div class="general-info-item-tip bold">
                {{ countInfo.totalAmount }}
            </div>
            销售总金额
        </div>
        <div class="general-info-item">
            <div class="general-info-item-tip bold">
                {{ priceAccurate }}
            </div>
            价格准确率
        </div>
        <div class="general-info-item">
            <div class="general-info-item-tip bold">
                {{ deliveryAccurate }}
            </div>
            货期准确率
        </div>
    </div>
</template>

<script>
import np from 'number-precision';
window.np = np;
export default {
    props: {
        countInfo: {},
    },
    computed: {
        priceAccurate() {
            return np.times(this.countInfo.priceAccurate, 100) + '%';
        },
        deliveryAccurate() {
            return np.times(this.countInfo.deliveryAccurate, 100) + '%';
        },
    },
};
</script>
<style lang="less" scoped>
.general-info {
    .general-info-item {
        margin-right: 30px;
    }
}
</style>
