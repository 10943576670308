<template>
    <div class="mt20" c-type="no-org">
        <Form
            ref="formValidate"
            :model="modelOrg"
            :rules="ruleValidate"
            :label-width="120"
        >
            <FormItem label="企业名称" prop="name">
                <OrgNameSelector
                    ref="orgNameSelector"
                    v-model="modelOrg.name"
                    class="w400"
                    :immediate="true"
                />
            </FormItem>

            <FormItem label="所在城市" prop="cityids">
                <ICity
                    ref="icity"
                    v-model="modelOrg.cityids"
                    class="w400"
                    :disabled="disabled"
                />
            </FormItem>
            <FormItem label="营业执照" prop="businessLicense">
                <IUpload
                    v-model="modelOrg.businessLicense"
                    :disabled="disabled"
                />
            </FormItem>
        </Form>
        <div class="flex-end">
            <Button class="bg-red bd-none c-white" @click="addOrg()"
                >加入企业</Button
            >
        </div>
    </div>
</template>
<script>
import IUpload from '../../../../../components/IUpload.vue';
import ICity from '../../../../../components/ICity.vue';
import cityUtil from '../../../../../util/cityUtil';
import OrgNameSelector from '@/components/OrgNameSelector.vue';
import currentOrgMixin from '@/mixins/currentOrg.mixin';

export default {
    name: 'NotHasOrg',
    components: {
        OrgNameSelector,
        IUpload,
        ICity,
    },
    mixins: [currentOrgMixin],
    data() {
        const nameValidator = async (rule, value, cb) => {
            await new Promise((resolve) => setTimeout(resolve, 300));
            if (!this.formValidate.name) {
                rule.message = '不能为空';
                cb(new Error('不能为空'));
                return;
            }

            cb();
        };

        return {
            modelOrg: {
                name: '',
                businessLicense: '',
                cityids: [],
            },
            options: [],
            ruleValidate: {
                cityids: [
                    {
                        required: true,
                        message: '所在城市不能为空',
                        trigger: 'blur',
                        validator: (rule, value, cb) => {
                            if (!this.modelOrg.cityids.length) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur',
                        validator: nameValidator,
                    },
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'change',
                        validator: nameValidator,
                    },
                ],
                businessLicense: [
                    {
                        required: true,
                        message: '营业执照不能为空',
                        trigger: 'blur',
                        validator: (rule, value, cb) => {
                            if (!this.modelOrg.businessLicense) {
                                cb(new Error('营业执照不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
            },
        };
    },
    computed: {
        disabled() {
            return typeof this.modelOrg.name === typeof 0;
        },
    },
    watch: {
        'modelOrg.name'() {
            this.changeOnSelect();
        },
    },
    mounted() {
        this.modelOrg.detailAddress = '';
    },
    methods: {
        getOptionsMap() {
            return this.$refs.orgNameSelector.options.reduce((t, c) => {
                return {
                    ...t,
                    [c.value]: c,
                };
            }, {});
        },
        changeOnSelect() {
            if (!this.disabled) {
                this.modelOrg.cityids = [];
                this.modelOrg.businessLicense = '';
                this.$nextTick(() => {
                    this.$refs.icity.updatePropValue();
                });
                return;
            }

            let optionsMap = this.getOptionsMap();

            let modelSelectedItem = optionsMap[this.modelOrg.name];

            let cityids = [
                modelSelectedItem.item.province,
                modelSelectedItem.item.city,
                modelSelectedItem.item.region,
            ];

            // 城市地址有问题默认空
            if (cityids.filter((it) => typeof it === typeof 0).length !== 3) {
                cityids = [];
            }
            this.modelOrg.cityids = cityids;
            this.modelOrg.businessLicense =
                modelSelectedItem.item.businessLicense;

            this.$nextTick(() => {
                this.$refs.icity.updatePropValue();
            });
        },
        async addOrg() {
            await new Promise((resolve) =>
                this.$refs.formValidate.validate((valid) => valid && resolve()),
            );

            let params = {
                // id,
                // name: modelSelectedItem.name,
                businessLicense: this.modelOrg.businessLicense,
                province: this.modelOrg.cityids[0],
                city: this.modelOrg.cityids[1],
                region: this.modelOrg.cityids[2],
            };
            if (this.disabled) {
                params.id = this.modelOrg.name;
            } else {
                params.name = this.modelOrg.name;
            }

            let res;
            if (this.disabled) {
                res = await $service.org.joinCommit(params);
            } else {
                res = await $service.org.addCurrentOrg(params);
            }
            //修改用户的企业信息后，在这里赋值新的token
            // $util.setToken(res.data.token);
            // todo fixme
            // this.$emit('getData');
            location.reload();
        },
    },
};
</script>
