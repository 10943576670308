<template>
    <div class="flex">
        <div v-for="(item, idx) in value" :key="idx" class="demo-upload-list">
            <img :src="item.url" width="100%" height="100%" />
            <div class="demo-upload-list-cover">
                <Icon type="ios-eye-outline" @click="$preview(item.url)"></Icon>
                <Icon
                    type="ios-trash-outline"
                    @click="handleRemove(idx)"
                ></Icon>
            </div>
        </div>
        <Upload
            v-if="value.length < 3"
            ref="upload"
            :show-upload-list="false"
            :default-file-list="defaultList"
            :on-success="handleSuccess"
            :format="['jpg', 'jpeg', 'png']"
            :max-size="2048"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :before-upload="handleBeforeUpload"
            multiple
            type="drag"
            action="/api/tools/upload"
            style="display: inline-block; width: 58px"
        >
            <div style="width: 58px; height: 58px; line-height: 58px">
                <Icon type="ios-camera" size="20"></Icon>
            </div>
        </Upload>
        <div class="c-red inline-block ml10" style="align-self: end">
            文件大小不能大于2M
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {},
    },
    data() {
        return {
            defaultList: [
                // {
                //     'name': 'image-demo-1.jpg',
                //     'url': 'https://file.iviewui.com/images/image-demo-1.jpg'
                // },
                // {
                //     'name': 'image-demo-2.jpg',
                //     'url': 'https://file.iviewui.com/images/image-demo-2.jpg'
                // }
            ],
            pathList: [],
            imgName: '',
            visible: false,
            uploadList: [],
        };
    },
    methods: {
        handleRemove(idx) {
            this.value.splice(idx, 1);
            this.$emit('input', this.value);
        },
        handleSuccess(res, file, list) {
            let path = res.data;
            let start = path.lastIndexOf('-');
            let filename = path.substr(start + 1, path.length - 1);
            file.url = path;
            file.name = filename;
            this.value.push(file);
            this.$emit('input', this.value);
        },
        handleFormatError(file) {
            this.$Notice.warning({
                title: 'The file format is incorrect',
                desc:
                    'File format of ' +
                    file.name +
                    ' is incorrect, please select jpg or png.',
            });
        },
        handleMaxSize() {
            this.$toast.warning('提示', '文件大小不能大于2M');
        },
        handleBeforeUpload() {
            const check = this.value.length < 3;
            if (!check) {
                this.$Notice.warning({
                    title: '文件不能超过3个',
                });
            }
            return check;
        },
    },
};
</script>

<style>
.demo-upload-list {
    display: inline-block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-right: 4px;
}
.demo-upload-list img {
    width: 100%;
    height: 100%;
}
.demo-upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
    display: block;
}
.demo-upload-list-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
}
</style>
