<template>
    <div class="mt20 relative" c-type="hasorg">
        <Form
            ref="formValidate"
            :model="formValidate"
            :rules="ruleValidate"
            :label-width="120"
        >
            <FormItem label="名称" prop="name">
                <div class="flex-v-center">
                    <div>
                        <OrgNameSelector
                            v-if="isEdit"
                            ref="orgNameSelector"
                            v-model="formValidate.name"
                            class="w400"
                            :immediate="true"
                        />
                        <span v-else>{{ orgData.name }}</span>
                    </div>

                    <div v-show="statusApproved" class="ml5 relative">
                        <!--                        <span class="dot-toast" v-if="orgData.status-2===0" @click="handleToast"></span>-->
                        <Tooltip
                            v-show="orgData.status - 2 === 0"
                            placement="right"
                            :content="orgData.rejectMark"
                        >
                            <img
                                style="height: 24px"
                                class="vertical-mid"
                                :src="statusApproved.img"
                                :alt="statusApproved.img"
                            />
                        </Tooltip>
                        <img
                            v-if="orgData.status - 2 !== 0"
                            style="height: 24px"
                            class="vertical-mid"
                            :src="statusApproved.img"
                            :alt="statusApproved.img"
                        />
                    </div>
                    <!-- 注释掉 -->
                    <!--                    <Tag v-if="orgType" color="blue"> {{ orgType }}</Tag>-->
                </div>
            </FormItem>
            <FormItem label="所在城市" prop="cityids">
                <ICity
                    v-if="isEdit"
                    ref="icity"
                    v-model="formValidate.cityids"
                    class="w400"
                    :disabled="disabled"
                />
                <span v-else>{{ addrText }}</span>
            </FormItem>
            <FormItem label="营业执照" prop="businessLicense">
                <IUpload
                    v-if="isEdit"
                    v-model="formValidate.businessLicense"
                    :disabled="disabled"
                />
                <ViewImg v-else :src="orgData.businessLicense" />
            </FormItem>
            <FormItem v-if="isAdmin">
<!--            <FormItem v-if="orgData.status && orgData.status === 2">-->
                <template v-if="isEdit" class="flex-end">
                    <Button class="mr10" type="primary" @click="handleUpdate">
<!--                        保存-->
                        重新申请
                    </Button>
                    <Button class="mr10" @click="handleToView"> 取消 </Button>
                </template>
                <Button
                    v-if="orgData.status && orgData.status === 2"
                    class="mr10"
                    type="primary"
                    @click="handleToEdit"
                >
                    编辑
                </Button>
                <Button
                    v-if="$userInfo.orgId && $userInfo.type === 2 && orgData.status === 1"
                    class="mr10"
                    type="primary"
                    @click="transfer"
                >
                    转让管理员
                </Button>
            </FormItem>
            <FormItem v-if="!isAdmin">
                <Button
                    v-if="$userInfo.orgId && $userInfo.type === 3"
                    class="mr10"
                    type="primary"
                    @click="out"
                >
                    退出企业
                </Button>
            </FormItem>
        </Form>
        <div>
            <Modal
                v-model="transferModal"
                title="转让管理员"
                :closable="false"
                :footer-hide="true"
            >
                <Select
                    v-model="transUid"
                    clearable
                    filterable
                    :loading="loading1"
                >
                    <Option
                        v-for="(option, index) in options1"
                        :key="index"
                        :value="option.value"
                        >{{ option.label }}
                    </Option>
                </Select>

                <div class="flex-end flex-v-center mt20">
                    <Button class="mr10" @click="transCancel">取消</Button>
                    <Button type="primary" @click="transOk">确定</Button>
                </div>
            </Modal>
        </div>

        <div>
            <Modal
                v-model="updatePasswordModel"
                title="修改密码"
                :closable="false"
                :footer-hide="true"
            >
                <UpdatePassword
                    v-if="updatePasswordModel"
                    @close="updatePasswordModel = false"
                />
            </Modal>
        </div>
    </div>
</template>

<script>
import ICity from '../../../../../components/ICity.vue';
import IUpload from '../../../../../components/IUpload.vue';
import cityUtil from '@/util/cityUtil';
import ViewImg from '@/components/ViewImg.vue';
import OrgNameSelector from '@/components/OrgNameSelector.vue';
import currentOrgMixin from '@/mixins/currentOrg.mixin';
import UpdatePassword from '@/pages/home/user/Index/Personal/UpdatePassword.vue';

// 0：认证中（未认证）  1：认证通过 2：认证未通过
let sc = {
    0: {
        style: { color: '#0a5495' },
        text: '认证中',
        img: require('@/assets/img/approve/process.png'),
    },
    1: {
        style: { color: '#00c395' },
        text: '认证通过',
        img: require('@/assets/img/approve/ok.png'),
    },
    2: {
        style: { color: '#999999' },
        text: '认证未通过',
        img: require('@/assets/img/approve/fail.png'),
    },
};

let isToasting = false; // 弹框只能点一次
export default {
    name: 'HasOrg',
    components: {
        UpdatePassword,
        OrgNameSelector,
        ViewImg,
        IUpload,
        ICity,
    },
    mixins: [currentOrgMixin],
    data() {
        const nameValidator = async (rule, value, cb) => {
            await new Promise((resolve) => setTimeout(resolve, 300));
            if (!this.formValidate.name) {
                rule.message = '不能为空';
                cb(new Error('不能为空'));
                return;
            }

            cb();
        };

        return {
            transferModal: false,
            updatePasswordModel: false,
            transUid: '',
            options1: [],
            loading1: false,
            isEdit: false,
            formValidate: {
                name: '',
                businessLicense: '',
                cityids: [],
            },
            ruleValidate: {
                cityids: [
                    {
                        required: true,
                        message: '所在城市不能为空',
                        trigger: 'blur',
                        validator: (rule, value, cb) => {
                            if (!this.formValidate.cityids.length) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur',
                        validator: nameValidator,
                    },
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'change',
                        validator: nameValidator,
                    },
                ],
                businessLicense: [
                    {
                        required: true,
                        message: '营业执照不能为空',
                        trigger: 'blur',
                        validator: (rule, value, cb) => {
                            if (!this.formValidate.businessLicense) {
                                cb(new Error('营业执照不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
            },
        };
    },
    computed: {
        disabled() {
            // # BUG-225  -- 逻辑冲突，全部可改
            // 企业认证失败后，用户重新编辑上传营业执照时无法操作；且所在城市不能编辑
            return false;
            // return typeof this.formValidate.name === typeof 0;
        },
        orgData() {
            return _.get(this.$currentOrg, 'org') || {};
        },
        addrText() {
            let { province, city, region } =
                _.get(this.$currentOrg, 'org') || {};
            if (!province || !city || !region) {
                return '-';
            }

            let provinceText = _.get(cityUtil.getItemByCode(province), 'text');
            let cityText = _.get(cityUtil.getItemByCode(city), 'text');
            let regionText = _.get(cityUtil.getItemByCode(region), 'text');

            if (provinceText === cityText) {
                return [provinceText, regionText].join('/');
            }

            return [provinceText, cityText, regionText].join('/');
        },
        isAdmin() {
            return !!_.get(this.$currentOrg, 'isAdmin');
        },
        statusApproved() {
            let status = _.get(this.$currentOrg, 'org.status');

            return sc[status];
        },
    },
    watch: {
        'formValidate.name'() {
            this.changeOnSelect();
        },
    },

    mounted() {
        this.transferSelect();
    },
    methods: {
        handleToast() {
            if (isToasting) {
                return;
            }
            let status = _.get(this.$currentOrg, 'org.status');
            if (status - 2 === 0) {
                isToasting = true;
                this.$toast.warning(
                    '资质认证失败原因',
                    _.get(this.$currentOrg, 'org.rejectMark') ||
                        '审核不通过, 请联系管理员',
                    {
                        duration: 0,
                        closable: true,
                        onClose: () => {
                            isToasting = false;
                        },
                    },
                );
            }
        },
        getOptionsMap() {
            return this.$refs.orgNameSelector.options.reduce((t, c) => {
                return {
                    ...t,
                    [c.value]: c,
                };
            }, {});
        },
        changeOnSelect() {
            if (!this.disabled) {
                // this.formValidate.cityids = [];
                // this.formValidate.businessLicense = '';
                this.$nextTick(() => {
                    this.$refs.icity.updatePropValue();
                });
                return;
            }

            let optionsMap = this.getOptionsMap();

            let modelSelectedItem = optionsMap[this.formValidate.name];

            let cityids = [
                modelSelectedItem.item.province,
                modelSelectedItem.item.city,
                modelSelectedItem.item.region,
            ];

            // 城市地址有问题默认空
            if (cityids.filter((it) => typeof it === typeof 0).length !== 3) {
                cityids = [];
            }
            this.formValidate.cityids = cityids;
            this.formValidate.businessLicense =
                modelSelectedItem.item.businessLicense;

            this.$nextTick(() => {
                this.$refs.icity.updatePropValue();
            });
        },
        handleToEdit() {
            let org = _.cloneDeep(this.orgData);
            org.cityids = [org.province, org.city, org.region];

            if (
                org.cityids.filter((it) => typeof it === typeof 0).length !== 3
            ) {
                org.cityids = [];
            }
            this.formValidate = org;
            this.isEdit = true;
        },
        handleToView() {
            this.isEdit = false;
        },
        async handleUpdate() {
            await new Promise((resolve) =>
                this.$refs.formValidate.validate((valid) => valid && resolve()),
            );

            let [province, city, region] = this.formValidate.cityids;

            let params = {
                businessLicense: this.formValidate.businessLicense,
                province,
                city,
                region,
            };
            if (this.disabled) {
                params.id = this.formValidate.name;
            } else {
                params.name = this.formValidate.name;
            }

            await $service.org.updateCurrentOrg(params);

            this.handleToView();
            location.reload();
        },

        out() {
            if (this.$userInfo.type === 2) {
                this.$Message.error('管理员用户请转让管理员身份后退出企业');
                return;
            }
            this.$Modal.confirm({
                title: '退出',
                content: '确定退出吗?',
                onOk: async () => {
                    let res = await $service.memeber.deleteFromOrg({
                        id: this.$userInfo.id,
                    });
                    this.$Message.success(res.message);
                    //刷新机构信息
                    location.reload();
                },
            });
        },
        transfer() {
            this.transferModal = true;
        },

        async transOk() {
            if (!this.transUid) {
                return;
            }
            await $service.memeber.transferAdmin({ id: this.transUid });
            this.$Message.info('操作成功');
            this.transCancel();
            location.reload();
        },
        transCancel() {
            this.transferModal = false;
        },

        async transferSelect(keywords) {
            let params = {
                pageSize: -1,
            };

            if (keywords) {
                params.username = keywords;
            }

            this.loading1 = true;

            try {
                let res = await $service.memeber.listByCondition(params);
                let userList = _.get(res, 'data.records') || [];
                const list = userList.map((item) => ({
                    value: item.id,
                    label: item.username,
                }));
                this.options1 = keywords
                    ? list.filter(
                          (item) =>
                              item.label
                                  .toLowerCase()
                                  .indexOf(keywords.toLowerCase()) > -1,
                      )
                    : list;
            } finally {
                this.loading1 = false;
            }
        },
    },
};
</script>
<style lang="less" scoped>
.dot-toast {
    position: absolute;
    top: 1px;
    right: -6px;
    width: 10px;
    height: 10px;
    background: #e54545;
    border-radius: 15px;
    cursor: pointer;
}
</style>
